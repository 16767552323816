import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  //const data = useSelector((state) => state.data);
  const [totalSupply, setTotalSupply] = useState(0);
  const [freeSaleStart, setFreeSaleStart] = useState(false);
  const [FreemintNum, setMintNum] = useState("");
  const [claimingFreeNft, setClaimingFreeNft] = useState(false);
  const [freeClaimed, setFreeClaimed] = useState(false);
  const [feedbackFree, setFeedbackFree] = useState(``);

  //const [pubSaleStart, setPubSaleStart] = useState(false);
  const [publicMintLimit, setPublicMintLimit] = useState(0);
  const [mintAmountPub, setMintAmountPub] = useState(1);
  const [claimingNft, setClaimingNft] = useState(false);
  const [publicClaimed, setPublicClaimed] = useState(0);
  const [feedback, setFeedback] = useState(``);

  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [showQuantitySelection, setShowQuantitySelection] = useState(false);

  const publicPhase = false;//手動管理
  const soldOut = false;//手動管理


  // config
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_FREE_COST: 0,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };


  //totalSupply
  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .totalSupply()
        .call()
        .then((receipt) => {
          setTotalSupply(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  // freeMint
  const checkFreeSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .freeSaleStart()
        .call()
        .then((receipt) => {
          setFreeSaleStart(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintable = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .mintable(blockchain.account)
        .call()
        .then((receipt) => {
          setMintNum(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  /*
  const claimFreeNFTs = async () => {
    let method = await blockchain.smartContract.methods.freeMint();
    setFeedbackFree(`購入手続き中...`);
    setClaimingFreeNft(true);
    try {
      // ガスの推定値を取得
      let gasEstimate = await method.estimateGas({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      });
      // 推定されたガス代に1.5をかける
      let gasLimit = Math.ceil(gasEstimate * 1.5);

      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        gas: gasLimit, // 計算したガス代を設定
      });
      console.log(receipt);
      setFeedbackFree(
        `ミントされました！`
      );
      setClaimingFreeNft(false);
      checkFreeClaimed();
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedbackFree("もう一度お試しください");
      setClaimingFreeNft(false);
    }
  };
  */


  const claimFreeNFTs = async () => {
    let method = await blockchain.smartContract.methods.freeMint();
    setFeedbackFree(`購入手続き中...`);
    setClaimingFreeNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      setFeedbackFree(
        `ミントされました！`
      );
      setClaimingFreeNft(false);
      checkFreeClaimed();
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedbackFree("もう一度お試しください");
      setClaimingFreeNft(false);
    }
  };

  const checkFreeClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .freeClaimed(blockchain.account)
        .call()
        .then((receipt) => {
          setFreeClaimed(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  // public
  /*
  const checkPubSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .publicSaleStart()
        .call()
        .then((receipt) => {
          setPubSaleStart(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };
  */

  const checkPublicMintLimit = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .publicMintLimit()
        .call()
        .then((receipt) => {
          setPublicMintLimit(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  /*
  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintAmountPub);
    // ウォレットの残高を取得
    let balance = await blockchain.web3.eth.getBalance(blockchain.account);
    let balanceWei = Number(balance);
    if (balanceWei < totalCostWei) {
      // ウォレットの残高が足りない場合、ユーザーに通知
      alert("ウォレットの残高が不足しています。");
      return;
    }
    // ミント関数のガス代を計算
    let method = await blockchain.smartContract.methods.publicMint(mintAmountPub);
    // ガスの推定値を取得
    let gasEstimate = await method.estimateGas({
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
      value: totalCostWei,
    });
    // 推定されたガス代に1.5をかける
    let gasLimit = Math.ceil(gasEstimate * 100);

    setFeedback(`購入手続き中...`);
    setClaimingNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gas: gasLimit, // 計算したガス代を設定
      });
      console.log(receipt);
      setFeedback(
        `ミントされました！`
      );
      setClaimingNft(false);
      checkPublicClaimed();
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedback("もう一度お試しください");
      setClaimingNft(false);
    }
  };
  */

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintAmountPub);
    // ウォレットの残高を取得
    let balance = await blockchain.web3.eth.getBalance(blockchain.account);
    let balanceWei = Number(balance);
    if (balanceWei < totalCostWei) {
      // ウォレットの残高が足りない場合、ユーザーに通知
      alert("ウォレットの残高が不足しています。");
      return;
    }
    // ミント関数のガス代を計算
    let method = await blockchain.smartContract.methods.publicMint(mintAmountPub);
    setFeedback(`購入手続き中...`);
    setClaimingNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      setFeedback(
        `ミントされました！`
      );
      setClaimingNft(false);
      checkPublicClaimed();
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedback("もう一度お試しください");
      setClaimingNft(false);
    }
  };

  const checkPublicClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .publicClaimed(blockchain.account)
        .call()
        .then((receipt) => {
          setPublicClaimed(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const decrementPublicMintAmount = () => {
    let newMintAmountPub = mintAmountPub - 1;
    if (newMintAmountPub < 1) {
      newMintAmountPub = 1;
    }
    setMintAmountPub(newMintAmountPub);
  };

  const decrementPublicMintAmount10 = () => {
    let newMintAmountPub = mintAmountPub - 10;
    if (newMintAmountPub < 1) {
      newMintAmountPub = 1;
    }
    setMintAmountPub(newMintAmountPub);
  };

  const incrementPublicMintAmount = () => {
    let newMintAmountPub = mintAmountPub + 1;
    if (newMintAmountPub > (publicMintLimit - publicClaimed)) {
      newMintAmountPub = publicMintLimit - publicClaimed;
    }
    //0より小さい場合は０にする
    if (newMintAmountPub < 0) {
      newMintAmountPub = 0;
    }
    setMintAmountPub(newMintAmountPub);
  };

  const incrementPublicMintAmount10 = () => {
    let newMintAmountPub = mintAmountPub + 10;
    if (newMintAmountPub > (publicMintLimit - publicClaimed)) {
      newMintAmountPub = publicMintLimit - publicClaimed;
    }
    //0より小さい場合は０にする
    if (newMintAmountPub < 0) {
      newMintAmountPub = 0;
    }
    setMintAmountPub(newMintAmountPub);
  };


  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkPublicMintLimit();
    checkFreeClaimed();
    checkPublicClaimed();
    //checkPubSaleStart();
    checkFreeSaleStart();
    checkMintable();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkPublicMintLimit();
    checkFreeClaimed();
    checkPublicClaimed();
    //checkPubSaleStart();
    checkFreeSaleStart();
    checkMintable();
  }, [blockchain.account]);

  return (
    <div style={{
      textAlign: "center",
      margin: "0 auto",
      // minHeight: "110vh",
      background: "#fff",//fff
      //background: "rgba(246, 103, 23, 1)",
      color: "#333",
      fontFamily: "'Mochiy Pop One', sans-serif",
      //backgroundImage: "url('/config/images/back.png')",
      //backgroundSize: "cover"
    }}>

      {/*
      <div style={{ margin: "0 auto", textAlign: "center", paddingTop: "16px", width: "100%", background: "rgba(246, 103, 23, 1)" }}>
        <img style={{ width: "90%", maxWidth: "640px" }} src="/config/images/top.png" alt="top" />
      </div>
      */}

      <div style={{ margin: "0 auto", textAlign: "center", paddingTop: "16px", width: "100%", background: "rgba(226, 112, 52, 1)", }}>
        <img style={{ width: "90%", maxWidth: "640px" }} src="/config/images/top_2.jpg" alt="top" />
      </div>

      <s.ResponsiveZone>


        {soldOut ? (
          <div style={{ marginTop: "20px",}}>
            <s.TextMsgTop style={{ fontSize: "22px", fontWeight: "400",marginTop: "6px" }}>
              完売したごじゃ! ありが冬瓜!!
            </s.TextMsgTop>
            <div style={{ margin: "0 auto", textAlign: "center", paddingTop: "6px", width: "100%"}}>
              <img style={{ width: "90%", maxWidth: "640px" }} src="/config/images/sold.jpg" alt="top" />
            </div>
          </div>
        ) : (
          <div>


            <s.MintZoneBottun>
              <s.Container flex={1} ai={"center"} style={{ maxHeight: "240px" }}>
                <>
                  {/*
              <div style={{ marginBottom: "16px", fontSize: "32px", fontWeight: "400", letterSpacing: "0.02em" }}>
                グルカタジェネ祭り
              </div>
              <div style={{ marginBottom: "16px", fontSize: "20px", fontWeight: "400", letterSpacing: "0.02em" }}>
                2/10 12:30〜 フリーミント
              </div>
              <div style={{ marginBottom: "16px", fontSize: "20px", fontWeight: "400", letterSpacing: "0.02em" }}>
                2/11 xx:xx〜 販売開始！
              </div>
            */}


                  {/*
              <div style={{ margin: "0px", fontSize: "26px", fontWeight: "400", letterSpacing: "0.02em" }}>
                今回の供給量＝{CONFIG.MAX_SUPPLY}
              </div>
              */}

                  {publicPhase ? (
                    <div>
                      <s.TextMsgTop style={{ fontSize: "16px", fontWeight: "400" }}>
                        〜 販売中(どなたでも購入可能です) 〜
                      </s.TextMsgTop>
                    </div>
                  ) : (
                    <div>
                      <s.TextMsgTop style={{ fontSize: "18px", fontWeight: "400" }}>
                        〜 フリーミント期間中 〜
                      </s.TextMsgTop>
                    </div>
                  )
                  }

                  {blockchain.account === "" || blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.Container ai={"center"} jc={"center"}></s.Container>
                      <s.SpacerSmall />
                      <s.StyledButton
                        style={{ borderRadius: "3px" ,fontSize: "11px"}}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        MetaMaskが使える方はこちら
                      </s.StyledButton>
                      <s.TextMsg style={{ fontSize: "12px", fontWeight: "400" }}>
                        ウォレットをコネクトします
                      </s.TextMsg>
                      <s.TextMsg style={{ fontSize: "12px", fontWeight: "400" }}>
                        (クレカ決済の方もコネクトしてください)
                      </s.TextMsg>

                      {publicPhase ? (//Phase確認(手動)
                        <div>
                          <s.StyledButton
                            style={{ marginTop: "20px", borderRadius: "3px" ,fontSize: "11px"}}
                            disabled={claimingNft ? 1 : 0}
                            onClick={() => {
                              setShowQuantitySelection(true);
                            }}
                          >
                            MetaMaskが使えない方はこちら
                          </s.StyledButton>
                          <s.TextMsg style={{ fontSize: "12px", fontWeight: "400" }}>※NFTを受け取るウォレットアドレスが必要です</s.TextMsg>
                          {showQuantitySelection && (
                            <s.Container ai={"center"} jc={"center"} fd={"column"}>
                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <s.StyledRoundButton
                                  style={{ lineHeight: 0.4 }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const newQuantity = selectedQuantity - 10;
                                    setSelectedQuantity(Math.max(newQuantity, 1));
                                  }}
                                >
                                  -10
                                </s.StyledRoundButton>

                                <s.StyledRoundButton
                                  style={{ lineHeight: 0.4 }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const newQuantity = selectedQuantity - 1;
                                    setSelectedQuantity(Math.max(newQuantity, 1));
                                  }}
                                >
                                  -
                                </s.StyledRoundButton>

                                <s.TextMsg style={{ fontSize: "24px", paddingBottom: "4px" }}>{selectedQuantity}</s.TextMsg>

                                <s.StyledRoundButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const newQuantity = selectedQuantity + 1;
                                    setSelectedQuantity(Math.min(newQuantity, 100));
                                  }}
                                >
                                  +
                                </s.StyledRoundButton>

                                <s.StyledRoundButton
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const newQuantity = selectedQuantity + 10;
                                    setSelectedQuantity(Math.min(newQuantity, 100));
                                  }}
                                >
                                  +10
                                </s.StyledRoundButton>
                              </s.Container>

                              <s.Container ai={"center"} jc={"center"} fd={"column"}>
                                <s.StyledButton
                                  style={{ width: "124px", borderRadius: "30px" }}
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={() => {
                                    const quantity = selectedQuantity; // カウンターの数値を取得
                                    window.open(`https://paypiement.xyz/projects/2f71e02a-623e-4458-9c16-b1ea55e25e92?quantity=${quantity}`);
                                  }}
                                >
                                  購入する
                                </s.StyledButton>

                                <s.TextMsg style={{ marginTop: "8px", fontSize: "12px", fontWeight: "400" }}>
                                  購入数を選択後[購入する]ボタンを押してください
                                </s.TextMsg>
                                <s.TextMsg style={{ fontSize: "12px", fontWeight: "400" }}>
                                  決済ページに移遷します(※手数料が発生します)
                                </s.TextMsg>
                              </s.Container>
                            </s.Container>
                          )}
                        </div>
                      ) : (
                        <div>
                          <s.StyledButton
                            style={{ marginTop: "20px", borderRadius: "3px", opacity: "0.5",fontSize: "11px"}}
                          >
                            MetaMaskが使えない方はこちら
                          </s.StyledButton>
                          <s.TextMsg style={{ fontSize: "12px", fontWeight: "400" }}>
                            一般販売(早押し)開始までしばらくお待ちください
                          </s.TextMsg>
                        </div>
                      )
                      }

                      <s.SpacerSmall />
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.TextMsg>
                            {blockchain.errorMsg}
                          </s.TextMsg>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerXSmall />
                        <div style={{ margin: "12px 0px", fontSize: "20px", fontWeight: "400", letterSpacing: "0.02em" }}>
                          ミント状況【{totalSupply} / {CONFIG.MAX_SUPPLY}】
                        </div>
                      </s.Container>
                    </>
                  )}
                </>
              </s.Container>


              {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <div>
                  <s.TextMsg style={{ fontSize: "22px", margin: "12px", fontWeight: "500" }}>完売したごじゃ! ありが冬瓜!!</s.TextMsg>
                  <div style={{ margin: "0 auto", textAlign: "center", paddingTop: "4px", width: "100%"}}>
                    <img style={{ width: "90%", maxWidth: "640px" }} src="/config/images/sold.jpg" alt="top" />
                  </div>
                  <s.StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    <img style={{ width: "32px", marginTop: "4px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
                  </s.StyledLink>
                </div>
              ) : (

                <s.MintPhaseResponsive>

                  {/* ------------------------ MetaMask利用 ------------------------ */}
                  <div>
                    <>
                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          {blockchain.errorMsg !== "" ? (
                            <></>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <div style={{ margin: '0px 8px' }}>
                            <s.TextMintPhase>フリーミント</s.TextMintPhase>
                          </div>
                          {FreemintNum > 0 ? (//FreeMint確認 変更点！
                            <div>
                              {freeSaleStart == true ? (
                                <div>
                                  {freeClaimed ? (
                                    <div>
                                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                        <s.StyledButtonClamed
                                          disabled={1}//claimingFreeNftがtrueなら disabledを表示させる。＝クリックできない
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          フリーミント済みです
                                        </s.StyledButtonClamed>
                                      </s.Container>
                                    </div>
                                  ) : (
                                    <div>
                                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                        <s.TextMsg>【{FreemintNum}枚】まとめてミントされます</s.TextMsg>
                                      </s.Container>
                                      <s.SpacerXSmall />
                                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                        <s.TextMsg>ガス代の支払い方法を選んでください</s.TextMsg>
                                      </s.Container>
                                      <s.SpacerXSmall />
                                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                        <s.StyledButton
                                          disabled={claimingFreeNft ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimFreeNFTs();
                                            getData();
                                          }}
                                        >
                                          {claimingFreeNft ? "ミント中です..." : "MATIC"}
                                        </s.StyledButton>
                                      </s.Container>
                                      <s.SpacerXSmall />
                                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                        <s.StyledButton
                                          disabled={claimingFreeNft ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                          onClick={() => {
                                            window.location.href = `https://paypiement.xyz/projects/9ae8a6bb-a81c-4e71-b3ca-f45a95188a2e?recipientAddress=${blockchain.account}`;
                                          }}
                                        >
                                          {/*claimingFreeNft ? "ミント中" : "ガス代を日本円(クレカ)で支払ってミント"*/}
                                          日本円(クレジットカード)
                                        </s.StyledButton>
                                      </s.Container>

                                      {/*freeClaimed > 0 ? (
                                    <></>
                                  ) : (
                                    <></>
                                  )*/}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledButtonClamed>
                                      フリーミント対象です
                                    </s.StyledButtonClamed>
                                  </s.Container>
                                  <s.TextMsg>開始までお待ちください({FreemintNum}ミントされます)</s.TextMsg>
                                </>
                              )}
                            </div>
                          ) : (
                            <div>
                              <s.StyledButtonClamed>
                                フリーミント対象ではありません
                              </s.StyledButtonClamed>
                            </div>
                          )}
                        </>
                      )}
                    </>
                    <s.TextMsg>{feedbackFree}</s.TextMsg>
                  </div>

                  {/* -------------------- MetaMask利用時Public -------------------- */}
                  <div>
                    <>
                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          {blockchain.errorMsg !== "" ? (
                            <>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <div>
                          {/*<div style={{ borderBottom: "solid 2px rgba(27, 102, 2, 1)", width: "64px", margin: "0 auto", paddingTop: "16px" }}></div>*/}

                          <div style={{ margin: '20px 8px 0px' }}>
                            <s.TextMintPhase>購入はこちらから / {CONFIG.DISPLAY_COST}MATIC</s.TextMintPhase>
                          </div>
                          {publicPhase ? (
                            <div>
                              {publicClaimed > publicMintLimit - 1 ? (
                                <div>
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledButtonClamed
                                      disabled={1}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      最大数購入済み
                                    </s.StyledButtonClamed>
                                  </s.Container>
                                </div>
                              ) : (
                                <>
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledRoundButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementPublicMintAmount10();
                                      }}
                                    >
                                      -10
                                    </s.StyledRoundButton>

                                    <s.StyledRoundButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementPublicMintAmount();
                                      }}
                                    >
                                      -
                                    </s.StyledRoundButton>

                                    <s.TextMsg style={{ fontSize: "24px", paddingBottom: "4px" }}>{mintAmountPub}</s.TextMsg>

                                    <s.StyledRoundButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementPublicMintAmount();
                                      }}
                                    >
                                      +
                                    </s.StyledRoundButton>

                                    <s.StyledRoundButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementPublicMintAmount10();
                                      }}
                                    >
                                      +10
                                    </s.StyledRoundButton>
                                  </s.Container>

                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledButton
                                      disabled={claimingNft ? 1 : 0}//claimingNftsがtrueなら disabledを表示させる。＝クリックできない
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                      }}
                                    >
                                      {claimingNft ? "購入中です..." : "MATICで購入"}
                                    </s.StyledButton>
                                  </s.Container>

                                  <s.SpacerXSmall />



                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={() => {
                                        // ボタンが押されたらmintAmountPubの値に基づいてリンク先に同じタブで遷移
                                        window.location.href = `https://paypiement.xyz/projects/2f71e02a-623e-4458-9c16-b1ea55e25e92?quantity=${mintAmountPub}&recipientAddress=${blockchain.account}`;
                                      }}
                                    >
                                      日本円(クレカ)で購入
                                    </s.StyledButton>
                                  </s.Container>

                                  {/*<s.TextMsg>最大{publicMintLimit}個購入することが出来ます</s.TextMsg>*/}
                                  {publicClaimed > 0 ? (
                                    <></>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <>
                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <s.StyledButtonClamed style={{ fontSize: "10px" }}>
                                  販売開始までしばらくお待ちください
                                </s.StyledButtonClamed>
                              </s.Container>

                              {/*<s.TextMsg>最大{publicMintLimit}個購入することが出来ます</s.TextMsg>*/}
                            </>
                          )}
                          <s.SpacerXSmall />
                          <s.WalletAddress>
                            ウォレットアドレス：{truncate(blockchain.account, 4)}{truncate(blockchain.account.slice(-4), 4)}
                          </s.WalletAddress>
                        </div>
                      )}
                    </>
                    <s.TextMsg>{feedback}</s.TextMsg>
                  </div>
                </s.MintPhaseResponsive>

              )}

            </s.MintZoneBottun>

          </div>
        )
        }

      </s.ResponsiveZone>
    </div>
  );
}
export default App;